<template>
  <div class="home">
    <div class="page-box">
      <div class="page-title">工作平台</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="breadcrumb-active"
          ><i class="fa fa-dashboard"></i>首页</el-breadcrumb-item
        >
        <el-breadcrumb-item>工作台</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-row type="flex" :gutter="24">
      <el-col :span="14">
        <!-- 常用功能 -->
        <div class="common-functions">
          <div class="title-box">
            <div class="title">常用功能</div>
            <div class="manage" @click="commonFunctionsDrawer = true">管理</div>
          </div>
          <div class="card-box">
            <div
              class="card-item"
              v-for="item in commonFunctions"
              :key="item.name"
              @click="Jump(item.name)"
            >
              <img :src="item.icon" />
              <div>{{ item.name }}</div>
            </div>
            <div
              class="card-item card-last"
              @click="commonFunctionsDrawer = true"
            >
              <img :src="require('@/assets/image/home/icon-tjcy.png')" />
              <div>添加常用</div>
            </div>
          </div>
        </div>
        <!-- 审批事项 -->
        <div class="examine">
          <div class="title-box">
            <div class="title">审批事项</div>
            <div class="manage" @click="goMore">更多</div>
          </div>
          <el-tabs v-model="activeName">
            <el-tab-pane
              :label="`待办事项(${
                todoList.length > 99 ? '99+' : todoList.length
              })`"
              name="first"
            >
              <div class="list">
                <div class="list-box" v-if="todoList.length !== 0">
                  <div
                    class="list-item"
                    v-for="(item, index) in todoList"
                    :key="index"
                    @click="goTo(item)"
                  >
                    <div class="left">
                      <div class="title">
                        <span>{{ item.WorkTableName }}</span>
                      </div>
                    </div>
                    <div class="date-timer">
                      <div class="date">{{ item.CreateDate }}</div>
                    </div>
                    <div class="right">
                      {{ item.CreateDate | dateFormater }}
                    </div>
                  </div>
                </div>
                <div class="tips-box" v-else>暂无数据</div>
              </div>
            </el-tab-pane>
            <el-tab-pane
              :label="`已办事项(${
                processedList.length > 99 ? '99+' : processedList.length
              })`"
              name="second"
            >
              <div class="list">
                <div class="list-box" v-if="processedList.length !== 0">
                  <div
                    class="list-item"
                    @click="goTo(item)"
                    v-for="(item, index) in processedList"
                    :key="index"
                  >
                    <div class="left">
                      <div class="title">
                        <span>{{ item.WorkTableName }}</span>
                      </div>
                    </div>
                    <div class="date-timer">
                      <div class="date">{{ item.CreateDate }}</div>
                    </div>
                    <div class="right">
                      {{ item.CreateDate | dateFormater }}
                    </div>
                  </div>
                </div>
                <div class="tips-box" v-else>暂无数据</div>
              </div></el-tab-pane
            >
            <el-tab-pane
              :label="`跟踪事项(${
                trackingList.length > 99 ? '99+' : trackingList.length
              })`"
              name="third"
            >
              <div class="list">
                <div class="list-box" v-if="trackingList.length !== 0">
                  <div
                    class="list-item"
                    v-for="(item, index) in trackingList"
                    :key="index"
                    @click="goTo(item)"
                  >
                    <div class="left">
                      <div class="title">
                        <span>{{ item.WorkTableName }}</span>
                      </div>
                    </div>
                    <div class="date-timer">
                      <div class="date">{{ item.CreateDate }}</div>
                    </div>
                    <div class="right">
                      {{ item.CreateDate | dateFormater }}
                    </div>
                  </div>
                </div>
                <div class="tips-box" v-else>暂无数据</div>
              </div></el-tab-pane
            >
            <el-tab-pane
              :label="`已结束(${endList.length > 99 ? '99+' : endList.length})`"
              name="fourth"
            >
              <div class="list">
                <div class="list-box" v-if="endList.length !== 0">
                  <div
                    class="list-item"
                    v-for="(item, index) in endList"
                    :key="index"
                    @click="goTo(item)"
                  >
                    <div class="left">
                      <div class="title">
                        <span>{{ item.WorkTableName }}</span>
                      </div>
                    </div>
                    <div class="date-timer">
                      <div class="date">{{ item.CreateDate }}</div>
                    </div>
                    <div class="right">
                      {{ item.CreateDate | dateFormater }}
                    </div>
                  </div>
                </div>
                <div class="tips-box" v-else>暂无数据</div>
              </div></el-tab-pane
            >
          </el-tabs>
        </div>
        <!-- 机票行程 -->
        <div class="flight-itinerary">
          <div class="title-box">
            <div class="title">机票行程</div>
            <div class="textbox">
              <el-date-picker
                v-model="daterange1"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="handleClick2"
              >
              </el-date-picker>
              <div class="input-box">
                <input
                  v-model="passCode"
                  type="text"
                  @blur="handleClick2"
                  placeholder="请输入护照号"
                />
                <img
                  class="search"
                  :src="require('@/assets/image/home/search.png')"
                />
              </div>
            </div>
          </div>
          <el-tabs v-model="activeName2" @tab-click="handleClick2">
            <el-tab-pane label="沙特接站" name="2">
              <div class="list" @scroll="scroll">
                <div class="list-box">
                  <div
                    class="list-item"
                    v-for="(item, index) in flightList"
                    :key="index"
                  >
                    <div class="left">
                      <div class="title-box">
                        <div>护照号</div>
                        <div class="number">{{ item.PassportCode }}</div>
                      </div>
                      <div class="time-box" v-if="item.ArrivalDateatKSA">
                        <div class="day">
                          {{ item.ArrivalDateatKSA | dateFormater2 }}
                        </div>
                        <div class="date">
                          {{ item.ArrivalDateatKSA | dateFormater1 }}
                        </div>
                      </div>
                      <div class="time-box" v-else>
                        <div class="day">10</div>
                        <div class="date">2023-10</div>
                      </div>
                    </div>
                    <div class="right">
                      <div class="location-left">
                        <div class="start_time">
                          {{ item.DeparatureTimefromKSA | dateFormater3 }}
                        </div>
                        <div class="start_airport">
                          {{
                            item.AirTicketRoute
                              | airlinesListFormater1(airlinesList)
                          }}
                        </div>
                      </div>
                      <div class="location-center">
                        <div class="aviation">{{ item.AirlineCode }}</div>
                        <img
                          class="aircraft"
                          :src="require('@/assets/image/home/aircraft.png')"
                        />
                        <div class="duration">
                          {{
                            item.ArrivalTimeatKSA
                              | calculateHoursAndMinutes(
                                item.DeparatureTimefromKSA
                              )
                          }}
                        </div>
                      </div>
                      <div class="location-right">
                        <div class="end_time">
                          {{ item.ArrivalTimeatKSA | dateFormater3 }}
                        </div>
                        <div class="end_airport">
                          {{
                            item.AirTicketRoute
                              | airlinesListFormater2(airlinesList)
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tips-box1" v-if="flightList.length === 0">
                    暂无数据
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="沙特送站" name="1">
              <div class="list" @scroll="scroll">
                <div class="list-box">
                  <div
                    class="list-item"
                    v-for="(item, index) in flightList"
                    :key="index"
                    v-if="flightList.length !== 0"
                  >
                    <div class="left">
                      <div class="title-box">
                        <div>护照号</div>
                        <div class="number">{{ item.PassportCode }}</div>
                      </div>
                      <div class="time-box" v-if="item.DeparatureTimefromKSA">
                        <div class="day">
                          {{ item.DeparatureTimefromKSA | dateFormater2 }}
                        </div>
                        <div class="date">
                          {{ item.DeparatureTimefromKSA | dateFormater1 }}
                        </div>
                      </div>
                      <div class="time-box" v-else>
                        <div class="day">10</div>
                        <div class="date">2023-10</div>
                      </div>
                    </div>
                    <div class="right">
                      <div class="location-left">
                        <div class="start_time">
                          {{ item.DeparatureTimefromKSA | dateFormater3 }}
                        </div>
                        <div class="start_airport">
                          {{
                            item.AirTicketRoute
                              | airlinesListFormater1(airlinesList)
                          }}
                        </div>
                      </div>
                      <div class="location-center">
                        <div class="aviation">{{ item.AirlineCode }}</div>
                        <img
                          class="aircraft"
                          :src="require('@/assets/image/home/aircraft.png')"
                        />
                        <div class="duration">
                          {{
                            item.ArrivalTimeatKSA
                              | calculateHoursAndMinutes(
                                item.DeparatureTimefromKSA
                              )
                          }}
                        </div>
                      </div>
                      <div class="location-right">
                        <div class="end_time">
                          {{ item.ArrivalTimeatKSA | dateFormater3 }}
                        </div>
                        <div class="end_airport">
                          {{
                            item.AirTicketRoute
                              | airlinesListFormater2(airlinesList)
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tips-box1" v-if="flightList.length === 0">
                    暂无数据
                  </div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-col>
      <el-col :span="10" class="col_right">
        <!-- 登录提示 -->
        <div class="login-prompt">
          <img
            class="weather"
            :src="require('@/assets/image/home/weather.png')"
          />
          <div class="tips-box">
            <div>
              {{
                $store.state.userInfo.RealName
                  ? $store.state.userInfo.RealName
                  : $store.state.userInfo.UserName
              }}你好！开启元气满满的一天～
            </div>
            <div class="tips">
              <span>登录于： {{ $store.state.userInfo.LastLoginDate }}</span>
              <!-- <span class="pm">PM13:00</span> -->
            </div>
          </div>
        </div>

        <!-- 通知公告 -->
        <div class="notice">
          <div class="title-box">
            <div class="title">通知公告</div>
            <div class="manage" @click="goMoreNotice">更多</div>
          </div>
          <div class="notice-card">
            <div
              class="notice-item"
              v-for="(item, index) in noticeList"
              :key="index"
              @click="showNoticeItem(item)"
            >
              <div class="left">
                <span class="circle"></span>
                <div class="name">{{ item.Title }}</div>
              </div>
              <div class="right">{{ item.SendTime }}</div>
            </div>
          </div>
        </div>
        <!-- 待我查阅 -->
        <div class="refer" ref="refer">
          <div class="title-box">
            <div class="title">待我查阅</div>
            <div class="manage" @click="goMore2">更多</div>
          </div>
          <div class="refer-box" v-if="readList.length !== 0">
            <div
              v-for="(item, index) in readList"
              :key="index"
              class="refer-item"
              @click="goTo2(item)"
            >
              <div class="refer-left">
                <span class="refer-circle"></span>
                {{ item.WorkTableName }}
              </div>
              <div class="refer-right">{{ item.CreateDate }}</div>
            </div>
          </div>
          <div class="tips-box" v-else>暂无数据</div>
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" :gutter="24">
      <el-col :span="24">
        <!-- 常用模版 -->
        <div class="template">
          <div class="title-box">
            <div class="title">常用模版</div>
          </div>
          <div class="card-box">
            <div
              class="card-item"
              @click="downloadTmplate(item)"
              v-for="(item, index) in templateList"
              :key="index"
            >
              <img :src="item.icon" />
              <div>{{ item.name }}</div>
            </div>
          </div>
        </div>
      </el-col></el-row
    >
    <el-row type="flex" :gutter="24">
      <el-col :span="24">
        <div class="footer">
          Copyright © 2024 HRMS/SPEC/SNEI All Rights Reserved.
        </div>
      </el-col>
    </el-row>

    <!-- 添加常用功能 -->
    <el-drawer
      title="常用功能"
      :visible.sync="commonFunctionsDrawer"
      :direction="direction"
      :before-close="handleFunctionClose"
      :with-header="false"
    >
      <div class="add-common">
        <div class="herder-box">
          <div class="left">
            <img
              class="arrow"
              :src="require('@/assets/image/home/arrow.png')"
            />
            <div>常用功能</div>
          </div>
          <img
            class="close"
            @click="handleFunctionClose"
            :src="require('@/assets/image/home/close.png')"
          />
        </div>
        <div class="line"></div>

        <div class="search-box">
          <input placeholder="搜索" />
          <img
            class="search"
            :src="require('@/assets/image/home/search2.png')"
          />
        </div>

        <div class="recent-searches">
          <div class="title">最近搜索</div>
          <div class="lable-box">
            <div class="leble" v-for="(item, index) in lables" :key="index">
              {{ item }}
            </div>
          </div>
        </div>

        <div class="card-box">
          <div
            class="card-item"
            v-for="(item, index) in allCommonFunctions"
            :key="index"
          >
            <div class="left">
              <img :src="item.icon" />
              <div class="name">{{ item.name }}</div>
            </div>
            <div class="right">
              <img
                v-if="!item.isActive"
                @click="addFunctions(index)"
                class="add"
                :src="require('@/assets/image/home/add.png')"
              />
              <div v-else class="delete-box">
                <div class="is-add">已添加</div>
                <img
                  class="delete"
                  @click="deleteFunctions(index)"
                  :src="require('@/assets/image/home/delete.png')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>

    <!-- 公告详情 -->
    <el-drawer
      title="公告详情"
      :visible.sync="noticeDrawer"
      :direction="direction"
      :before-close="handleNoticeClose"
      :with-header="false"
    >
      <div class="add-common">
        <div class="herder-box">
          <div class="left">
            <img
              class="arrow"
              :src="require('@/assets/image/home/arrow.png')"
            />
            <div>公告详情</div>
          </div>
          <img
            class="close"
            @click="handleNoticeClose"
            :src="require('@/assets/image/home/close.png')"
          />
        </div>
        <div class="line"></div>
        <div class="content">
          <div class="title">标题: {{ Title }}</div>
          <div class="time-box">
            <span>发布于{{ SendTime }}</span>
          </div>
          <div class="html" v-html="html"></div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import {
  getHomeTodo,
  getHomeProcessed,
  getHomeTracking,
  getHomeEnd,
  getNews,
  getNewsId,
  getView,
  getPositionTemplate,
  getCostCentersTemplate,
} from "@/api/user.js";
import { getDropOfforPickup, getAirlinesAll } from "@/api/table";
export default {
  filters: {
    dateFormater(data) {
      let date1 = new Date(data);
      let date2 = new Date();
      let diffInMilliseconds = Math.abs(date2 - date1);
      let daysDiff = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
      let hoursDiff = Math.floor(
        (diffInMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );

      if (daysDiff) {
        return "已滞留超过24小时";
      }
      return hoursDiff === 0 ? "刚刚" : hoursDiff + "小时前";
    },

    dateFormater1(data) {
      if (data) {
        data = data.slice(0, 7);
      }
      return data;
    },
    dateFormater2(data) {
      if (data) {
        data = data.slice(8, 10);
      }
      return data;
    },
    dateFormater3(data) {
      if (data) {
        data = data.slice(11, 16);
      }
      return data;
    },
    airlinesListFormater1(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.DeparatureCityName : null;
    },
    airlinesListFormater2(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.ArrivalCityName : null;
    },

    calculateHoursAndMinutes(date1, date2) {
      // 计算两个日期之间的毫秒差
      if (!date1 || !date2) {
        return "无";
      }
      date1 = new Date(date1);
      date2 = new Date(date2);
      const diff = date2.getTime() - date1.getTime();

      // 将毫秒差转换为小时和分钟
      let hours = Math.abs(diff / (1000 * 60 * 60));
      let minutes = Math.abs((diff % (1000 * 60 * 60)) / (1000 * 60));

      // 返回一个对象，包含相差的小时和分钟
      return `${hours ? Math.floor(hours) + "小时" : ""}${
        minutes ? Math.floor(minutes) + "分钟" : ""
      }`;
    },
  },
  data() {
    return {
      commonFunctions: [
        {
          name: "人员审批",
          icon: require("@/assets/image/home/icon-rysp.png"),
        },
        {
          name: "签证办理",
          icon: require("@/assets/image/home/icon-qzbl.png"),
        },
        {
          name: "任务批件",
          icon: require("@/assets/image/home/icon-rwpj.png"),
        },
        {
          name: "人员动迁",
          icon: require("@/assets/image/home/icon-rydq.png"),
        },
      ],
      allCommonFunctions: [
        {
          name: "人员审批",
          icon: require("@/assets/image/home/icon-rysp.png"),
          isActive: true,
        },
        {
          name: "签证办理",
          icon: require("@/assets/image/home/icon-qzbl.png"),
          isActive: true,
        },
        {
          name: "任务批件",
          icon: require("@/assets/image/home/icon-rwpj.png"),
          isActive: true,
        },
        {
          name: "人员动迁",
          icon: require("@/assets/image/home/icon-rydq.png"),
          isActive: true,
        },
        {
          name: "机票管理",
          icon: require("@/assets/image/home/icon-jpgl.png"),
          isActive: false,
        },
      ],
      serachCommonFunctions: [],
      activeName: "first",
      activeName2: "2",
      examineList: [
        {
          isProcessed: false, //是否办理
          isEmergent: 1,
          isFire: true,
          date: "2024-01-04",
          timer: "16:00",
          tips: "已滞留24小时",
          title: "请签字xxx提交的申请请签字xxx提交的申请",
        },
        {
          isProcessed: false, //是否办理
          isEmergent: 2,
          isFire: true,
          date: "2024-01-04",
          timer: "16:00",
          tips: "已滞留24小时",
          title: "请签字xxx提交的申请",
        },
        {
          isProcessed: false, //是否办理
          isEmergent: 1,
          isFire: false,
          date: "2024-01-04",
          timer: "",
          tips: "2小时前",
          title: "请签字xxx提交的申请",
        },
        {
          isProcessed: false, //是否办理
          isEmergent: 2,
          isFire: false,
          date: "2024-01-04",
          timer: "",
          tips: "2小时前",
          title: "请签字xxx提交的申请",
        },
        {
          isProcessed: false, //是否办理
          isEmergent: 1,
          isFire: false,
          date: "2024-01-04",
          timer: "",
          tips: "2小时前",
          title: "请签字xxx提交的申请",
        },
      ],
      noticeList: [],
      flightList: [
        // {
        //   passportNumber: "123456",
        //   day: "10",
        //   date: "2024.01",
        //   start_time: "10:00",
        //   start_airport: "虹桥T2",
        //   aviation: "海南航空HN1234",
        //   duration: "10个小时",
        //   end_time: "10:00",
        //   end_airport: "沙特T2",
        // },
        // {
        //   passportNumber: "123456",
        //   day: "10",
        //   date: "2024.01",
        //   start_time: "10:00",
        //   start_airport: "虹桥T2",
        //   aviation: "海南航空HN1234",
        //   duration: "10个小时",
        //   end_time: "10:00",
        //   end_airport: "沙特T2",
        // },
      ],
      airlinesList: [],
      templateList: [
        {
          name: "成本中心",
          icon: require("@/assets/image/home/template1.png"),
        },
        {
          name: "岗位职务",
          icon: require("@/assets/image/home/template2.png"),
        },
      ],
      lables: ["任务审批", "任务审批任务审批"],
      value1: null,
      value2: null,
      commonFunctionsDrawer: false,
      direction: "rtl",
      noticeDrawer: false,
      html: "",
      Title: "",
      SendTime: "",
      todoList: [],
      processedList: [],
      trackingList: [],
      endList: [],
      url: "https://hrms.baishulai.com/",
      readList: [],
      page: 1,
      page2:1,
      isFlage: false,
      daterange1: null,
      passCode: null,
    };
  },
  methods: {
    Jump(name) {
      switch (name) {
        case "人员审批":
          location.href =
            "https://hrms.baishulai.com/pages/PQDQ/PersonReview.aspx?StatusID=-1";
          break;
        case "签证办理":
          location.href =
            "https://hrms.baishulai.com/pages/PQDQ/Visa.aspx?VisaStatus=-1";
          break;
        case "任务批件":
          location.href =
            "https://hrms.baishulai.com/pages/PQDQ/Foreign.aspx?ForeignStatus=-1";
          break;
        case "人员动迁":
          location.href =
            "https://hrms.baishulai.com/pages/PQDQ/HomeRelocation.aspx?StatusID=-1";
          break;
        case "机票管理":
          location.href =
            "https://hrms.baishulai.com/pages/PQDQ/PlaneTicket.aspx?IsHidden=2";
          break;
      }
    },
    getDropOfforPickup() {
      //起飞时间
      let departureStartDate = "";
      let departureEndDate = "";
      // 到达时间
      let arrivalStartDate = "";
      let arrivalEndDate = "";

      if (this.daterange1) {
        if (this.activeName2 == 2) {
          arrivalStartDate = this.daterange1[0];
          arrivalEndDate = this.daterange1[1];
        }
        if (this.activeName2 == 1) {
          departureStartDate = this.daterange1[0];
          departureEndDate = this.daterange1[1];
        }
      }

      getDropOfforPickup({
        page: this.page2,
        size: 4,
        dropOfforPickup: this.activeName2,
        departureStartDate,
        departureEndDate,
        arrivalStartDate,
        arrivalEndDate,
        passportCode: this.passCode,
      }).then((res) => {
        console.log("res", res);
        if (res.status === 200) {
          if (res.response.data.length !== 0) {
            this.flightList = this.flightList.concat(res.response.data);
            this.page2++;
            this.isFlage = true;
          }
        }
      });
    },
    handleClick() {
      // this.page = 1;
      // this.flightList = [];
      // this.getDropOfforPickup();
    },
    handleClick2() {
      this.page2 = 1;
      this.flightList = [];
      this.getDropOfforPickup();
    },
    scroll(event) {
      //  console.log('event',event.target)
      // console.log('event',event.target.scrollTop)
      if (event.target.scrollTop === event.target.scrollTopMax) {
        console.log("页面滚动到底部");
        if (this.isFlage) {
          this.isFlage = false;
          this.getDropOfforPickup();
        }
      }
    },
    //添加常用功能抽屉关闭
    handleFunctionClose() {
      this.commonFunctionsDrawer = false;
    },
    // 公告详情关闭
    handleNoticeClose() {
      this.noticeDrawer = false;
    },
    //点击添加常用应用
    addFunctions(index) {
      this.allCommonFunctions[index].isActive = true;
      this.getNewCommonFunctions();
    },
    //删除添加常用应用
    deleteFunctions(index) {
      this.allCommonFunctions[index].isActive = false;
      this.getNewCommonFunctions();
    },
    //遍历addFunctions生成新数据
    getNewCommonFunctions() {
      let arr = [];
      this.allCommonFunctions.forEach((item) => {
        if (item.isActive) {
          arr.push(item);
        }
      });
      this.commonFunctions = JSON.parse(JSON.stringify(arr));
    },

    //待办事项
    getHomeTodo() {
      getHomeTodo().then((res) => {
        if (res.status === 200) {
          this.todoList = res.response;
        }
      });
    },
    //已办事项
    getHomeProcessed() {
      getHomeProcessed().then((res) => {
        if (res.status === 200) {
          this.processedList = res.response;
        }
      });
    },
    //跟踪事项
    getHomeTracking() {
      getHomeTracking().then((res) => {
        if (res.status === 200) {
          this.trackingList = res.response;
        }
      });
    },
    //结束事项
    getHomeEnd() {
      getHomeEnd().then((res) => {
        if (res.status === 200) {
          this.endList = res.response;
        }
      });
    },
    //通知公告
    getNews() {
      const data = {
        page: 1,
        size: 10,
      };
      getNews(data).then((res) => {
        if (res.status === 200) {
          this.noticeList = res.response.data;
        }
      });
    },
    //获取通知公告详情
    showNoticeItem(data) {
      getNewsId({ id: data.ID }).then((res) => {
        if (res.status === 200) {
          this.noticeDrawer = true;
          this.Title = res.response.Title;
          this.html = res.response.MsgContent;
          this.SendTime = data.SendTime;
        }
      });
    },
    goMoreNotice() {
      location.href = this.url + "/pages/NEWS/News.aspx";
    },
    //跳转
    goTo(data) {
      let index = null;
      switch (this.activeName) {
        case "first":
          index = 1;
          break;
        case "second":
          index = 2;
          break;
        case "third":
          index = 3;
          break;
        case "fourth":
          index = 4;
          break;
      }
      this.$router.push(
        `/module/examine${index}?key=${data.WorkFlowTable_Id}&status=${index}`
      );
    },
    goTo2(data) {
      this.$router.push(
        `/module/examine${index}?key=${data.WorkFlowTable_Id}&status=${5}`
      );
    },
    //更多
    goMore() {
      let type = null;
      switch (this.activeName) {
        case "first":
          type = 1;
          break;
        case "second":
          type = 2;
          break;
        case "third":
          type = 3;
          break;
        case "fourth":
          type = 4;
          break;
      }
      this.$router.push(`/module/examine${type}?status=${type}`);
    },
    //更多2
    goMore2() {
      this.$router.push(`/module/consult?status=${5}`);
    },
    //待我查阅
    getView() {
      getView().then((res) => {
        if (res.status === 200) {
          this.readList = res.response;
        }
      });
    },
    //下载模板
    downloadTmplate(data) {
      switch (data.name) {
        case "成本中心":
          getCostCentersTemplate().then((res) => {
            if (res.status === 200) {
              let url = res.response;
              let charIndex = url.indexOf("Download");
              url = url.substr(charIndex, url.length);
              url = url.replace(/\\/g, "/");
              location.href = "https://hrms.baishulai.com/" + url;
            }
          });
          break;
        case "岗位职务":
          getPositionTemplate().then((res) => {
            if (res.status === 200) {
              let url = res.response;
              let charIndex = url.indexOf("Download");
              url = url.substr(charIndex, url.length);
              url = url.replace(/\\/g, "/");
              location.href = "https://hrms.baishulai.com/" + url;
            }
          });
          break;
      }
    },
  },
  created() {
    this.getHomeTodo();
    this.getHomeProcessed();
    this.getHomeTracking();
    this.getHomeEnd();
    this.getNews();
    this.getView();
    //全部飞行路线
    getAirlinesAll().then((res) => {
      if (res.status === 200) {
        this.airlinesList = res.response;
        console.log("res", res);
      }
    });
    this.handleClick();
    this.handleClick2()
  },
};
</script>
<style lang="scss" scoped>
.home {
  width: 100%;
  min-height: 100%;
  background-color: #ecf0f5;
  box-sizing: border-box;
  padding: 24px;

  .page-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .page-title {
      font-size: 18px;
      color: #000000;
    }
    .breadcrumb-active {
      color: #303133;
      font-weight: bolder;
      cursor: pointer;
      .fa {
        margin-right: 5px;
      }
    }
  }
  .el-row {
    // 常用功能
    .common-functions {
      width: 100%;
      min-height: 164px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      margin-top: 24px;
      padding: 18px;
      box-sizing: border-box;
      .title-box {
        display: flex;
        justify-content: space-between;
        .title {
          font-size: 16px;
          color: #333333;
          font-weight: bolder;
        }
        .manage {
          color: #337ab7;
          font-size: 14px;
          cursor: pointer;
        }
      }
      .card-box {
        display: flex;
        flex-wrap: wrap;
        font-size: 12px;
        color: #333333;
        .card-item {
          width: 64px;
          flex-shrink: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-right: 50px;
          margin-top: 20px;
          cursor: pointer;
          img {
            width: 48px;
            height: 48px;
            margin-bottom: 12px;
          }
        }
        .card-last {
          margin-right: 0;
        }
      }
    }
    // 登录提示
    .login-prompt {
      width: 100%;
      height: 136px;
      background-image: url("../../assets/image/home/bg1.png");
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      margin-top: 24px;
      display: flex;
      align-items: center;
      .weather {
        width: 62px;
        height: 62px;
        margin-left: 23px;
        margin-right: 26px;
      }
      .tips-box {
        font-size: 14px;
        color: #fff;
        .tips {
          margin-top: 17px;
        }
        .pm {
          margin-left: 24px;
        }
      }
    }
    //待我审批
    .examine {
      width: 100%;
      min-height: 303px;
      background: #ffffff;
      border-radius: 4px;
      margin-top: 24px;
      padding: 18px;
      box-sizing: border-box;
      .title-box {
        display: flex;
        justify-content: space-between;
        .title {
          font-size: 16px;
          color: #333333;
          font-weight: bolder;
        }
        .manage {
          color: #337ab7;
          font-size: 14px;
          cursor: pointer;
        }
      }
      .el-tabs {
        margin-top: 12px;
      }
      .list {
        height: 200px;
        overflow: auto;
        position: relative;
        .list-box {
          display: flex;
          flex-direction: column;
          position: relative;
          .list-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 18px;
            cursor: pointer;
            .left {
              display: flex;
              align-items: center;
              .processed {
                width: 6px;
                height: 6px;
                background: #ff8c38;
                opacity: 1;
                border-radius: 3px;
                margin-right: 11px;
              }

              .title {
                width: 200px;
                overflow: hidden; //超出的文本隐藏
                text-overflow: ellipsis; //溢出用省略号显示
                white-space: nowrap; // 默认不换行
                .emergent {
                  display: inline-block;
                  width: 34px;
                  height: 16px;
                  line-height: 16px;
                  background: #ecf6ff;
                  border-radius: 2px 2px 2px 2px;
                  opacity: 1;
                  color: #316ff5;
                  font-size: 10px;
                  text-align: center;
                  margin-right: 16px;
                  position: relative;
                  top: -3px;
                }
                .emergent2 {
                  display: inline-block;
                  width: 34px;
                  height: 16px;
                  line-height: 16px;
                  background: #e5faf2;
                  border-radius: 2px 2px 2px 2px;
                  opacity: 1;
                  color: #009d67;
                  font-size: 10px;
                  text-align: center;
                  margin-right: 16px;
                  position: relative;
                  top: -3px;
                }
                color: #333333;
                font-size: 14px;
                margin-right: 10px;
              }
              .is-fire {
                width: 14px;
                height: 14px;
                margin-right: 50px;
                .fire {
                  width: 100%;
                  height: 100%;
                }
              }
            }
            .date-timer {
              display: flex;
              justify-content: flex-start;
              flex-shrink: 0;
              width: 150px;
              text-align: left;
              margin-right: 50px;
              .date {
                color: #999999;
                margin-right: 12px;
                font-size: 14px;
              }
              .timer {
                color: #999999;
                font-size: 14px;
              }
            }
            .right {
              text-align: right;
              width: 120px;
              color: #333333;
              font-size: 14px;
            }
          }
        }
        .tips-box {
          font-size: 14px;
          position: absolute;
          left: 50%;
          top: 20%;
          transform: translateX(-50%);
          color: #5e6d82;
        }
      }
    }
    //通知公告
    .notice {
      width: 100%;
      min-height: 200px;
      background: #ffffff;
      border-radius: 4px;
      margin-top: 24px;
      padding: 18px;
      box-sizing: border-box;
      .title-box {
        display: flex;
        justify-content: space-between;
        .title {
          font-size: 16px;
          color: #333333;
          font-weight: bolder;
        }
        .manage {
          color: #337ab7;
          font-size: 14px;
          cursor: pointer;
        }
      }
      .notice-card {
        height: 148.8px;
        overflow: auto;
        .notice-item {
          display: flex;
          justify-content: space-between;
          margin-top: 18px;
          cursor: pointer;
          .left {
            display: flex;
            align-items: center;
            flex-shrink: 0;
            .circle {
              display: inline-block;
              width: 5px;
              height: 5px;
              background: #dedede;
              opacity: 1;
              border-radius: 2.5px;
              margin-right: 10px;
            }
            .name {
              display: inline-block;
              max-width: 170px;
              overflow: hidden; //超出的文本隐藏
              text-overflow: ellipsis; //溢出用省略号显示
              white-space: nowrap; // 默认不换行
              font-size: 14px;
            }
          }
          .right {
            color: #999999;
            font-size: 14px;
            flex-shrink: 0;
          }
        }
      }
    }
    //机票行程
    .flight-itinerary {
      width: 100%;
      min-height: 328px;
      background: #ffffff;
      border-radius: 4px;
      margin-top: 24px;
      padding: 18px;
      box-sizing: border-box;
      .title-box {
        display: flex;
        justify-content: space-between;
        .title {
          font-size: 16px;
          color: #333333;
          font-weight: bolder;
        }
        .textbox {
          display: flex;
          align-items: center;
          .el-date-editor {
            width: 230px;
          }
          .input-box {
            position: relative;
            margin-left: 25px;
            input {
              width: 150px;
              height: 40px;
              box-sizing: border-box;
              padding-left: 11px;
              padding-right: 40px;
              border: none;
              outline: none;
              border: 1px solid #dcdfe6;
              border-radius: 4px;
            }
            .search {
              position: absolute;
              width: 18px;
              height: 18px;
              right: 10px;
              top: 11px;
            }
          }
        }
      }
      .tips-box1 {
        position: absolute;
        font-size: 14px;
        width: 100px;
        text-align: center;
        left: 50%;
        top: 60px;
        transform: translateX(-50%);
        color: #5e6d82;
      }
      .list {
        height: 210px;
        overflow: auto;
        .list-box {
          display: flex;
          flex-direction: column;
          position: relative;
          .list-item {
            width: 100%;
            height: 86px;
            background: #fafafa;
            border-radius: 4px 4px 4px 4px;
            margin-top: 19px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .left {
              display: flex;
              .title-box {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                margin-left: 19px;
                font-size: 14px;
                color: #333333;
                .number {
                  margin-top: 10px;
                }
              }
              .time-box {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                margin-left: 19px;
                font-size: 14px;
                color: #333333;
                margin-left: 45px;
                color: #337ab7;
                .day {
                  font-size: 19px;
                  font-weight: bolder;
                }
                .date {
                  font-size: 14px;
                }
              }
            }
            .right {
              width: 100%;
              margin-left: 35px;
              height: 78px;
              background: #ffffff;
              border-radius: 4px;
              margin-right: 4px;
              display: flex;
              justify-content: space-around;
              align-items: flex-end;
              .location-left {
                min-width: 50px;
                margin-bottom: 9px;
                display: flex;
                flex-direction: column;
                align-items: center;
                font-size: 14px;
                color: #333333;
                .start_time {
                  font-weight: bolder;
                  margin-bottom: 9px;
                }
              }
              .location-center {
                margin-bottom: 9px;
                min-width: 100px;
                flex-shrink: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                font-size: 14px;
                color: #333333;
                .aircraft {
                  width: 42px;
                  height: 14px;
                  margin-bottom: 8px;
                }
                .aviation {
                  font-size: 12px;
                  margin-bottom: 5px;
                }
              }
              .location-right {
                min-width: 50px;
                margin-bottom: 9px;
                display: flex;
                flex-direction: column;
                align-items: center;
                font-size: 14px;
                color: #333333;
                .end_time {
                  font-weight: bolder;
                  margin-bottom: 9px;
                }
              }
            }
          }
        }
      }
    }
    //待我查阅
    .refer {
      width: 100%;
      height: calc(100% - 413px);
      background: #ffffff;
      border-radius: 4px;
      margin-top: 24px;
      padding: 18px;
      box-sizing: border-box;
      position: relative;
      .title-box {
        display: flex;
        justify-content: space-between;
        .title {
          font-size: 16px;
          color: #333333;
          font-weight: bolder;
        }
        .manage {
          color: #337ab7;
          font-size: 14px;
          cursor: pointer;
        }
      }
      .refer-box {
        min-height: 370px;
        max-height: 370px;
        overflow: auto;
        margin-top: 24px;
        position: relative;
        .refer-item {
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          margin-bottom: 20px;
          .refer-left {
            width: 300px;
            overflow: hidden; //超出的文本隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            white-space: nowrap; // 默认不换行
            color: #333333;
            .refer-circle {
              display: inline-block;
              width: 5px;
              height: 5px;
              border-radius: 2.5px;
              background-color: #dedede;
              margin-right: 5px;
            }
          }
          .refer-right {
            color: #999999;
          }
        }
      }
      .tips-box {
        font-size: 14px;
        position: absolute;
        left: 50%;
        top: 20%;
        transform: translateX(-50%);
        color: #5e6d82;
      }
    }
    //常用模板
    .template {
      width: 100%;
      min-height: 181px;
      background: #ffffff;
      border-radius: 4px;
      margin-top: 24px;
      padding: 18px;
      box-sizing: border-box;
      .title-box {
        display: flex;
        .title {
          font-size: 16px;
          color: #333333;
          font-weight: bolder;
        }
      }
      .card-box {
        display: flex;
        flex-wrap: wrap;
        font-size: 12px;
        color: #333333;
        .card-item {
          flex-shrink: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-right: 70px;
          margin-top: 36px;
          cursor: pointer;
          img {
            width: 48px;
            height: 48px;
            margin-bottom: 12px;
          }
        }
        .card-last {
          margin-right: 0;
        }
      }
    }
    //页脚
    .footer {
      text-align: center;
      margin-top: 24px;
      font-size: 14px;
      color: #06052b;
    }
  }
  .add-common {
    width: 100%;
    height: 100%;
    padding: 20px 25px;
    box-sizing: border-box;
    .html {
      word-wrap: break-word; /* 旧版浏览器支持 */
      overflow-wrap: break-word; /* 标准属性 */
    }
    .herder-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #333333;
        cursor: pointer;
        font-weight: bolder;
        .arrow {
          width: 16px;
          height: 16px;
          margin-right: 5px;
        }
      }
      .close {
        cursor: pointer;
        width: 16px;
        height: 16px;
      }
    }
    .line {
      width: 100%;
      border: 1px solid #ededed;
      margin-top: 25px;
    }
    .search-box {
      position: relative;
      margin-top: 21px;
      input {
        width: 100%;
        height: 36px;
        box-sizing: border-box;
        border: none;
        padding-left: 44px;
        padding-right: 11px;
        outline: none;
        border: 1px solid #dcdfe6;
        border-radius: 4px;
      }
      .search {
        position: absolute;
        top: 10px;
        left: 13px;
        width: 16px;
        height: 16px;
      }
    }
    .recent-searches {
      .title {
        margin-top: 37px;
        font-size: 14px;
        color: #333333;
        margin-bottom: 31px;
      }
      .lable-box {
        display: flex;
        flex-wrap: wrap;
        .leble {
          height: 30px;
          line-height: 30px;
          background: #efefef;
          border-radius: 2px;
          padding: 0 10px;
          margin-right: 24px;
          font-size: 14px;
          color: #333333;
        }
      }
    }
    .card-box {
      display: flex;
      flex-direction: column;
      margin-top: 16px;
      .card-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 48px;
        margin-bottom: 15px;
        .left {
          display: flex;
          font-size: 14px;
          color: #333333;
          align-items: center;
          img {
            width: 36px;
            height: 36px;
            margin-right: 26px;
          }
        }
        .right {
          .add {
            width: 16px;
            height: 16px;
            cursor: pointer;
          }
          .delete-box {
            display: flex;
            align-items: center;
            .is-add {
              width: 58px;
              height: 30px;
              border-radius: 4px 4px 4px 4px;
              opacity: 1;
              border: 1px solid #cccccc;
              color: #cccccc;
              font-size: 14px;
              line-height: 30px;
              text-align: center;
              margin-right: 26px;
            }
            .delete {
              width: 16px;
              height: 16px;
              cursor: pointer;
            }
          }
        }
      }
    }
    .content {
      .title {
        font-size: 14px;
        color: #333333;
        margin-top: 27px;
      }
      .time-box {
        font-size: 12px;
        color: #999999;
        margin-top: 18px;
        margin-bottom: 18px;
        span {
          margin-right: 30px;
        }
      }
      .html {
        color: #333333;
        font-size: 14px;
      }
    }
  }
}
</style>
<style scoped>
.examine >>> .is-active {
  color: #337ab7;
}
.examine >>> .el-tabs__active-bar {
  height: 2px;
  background-color: #337ab7;
}
.examine >>> .el-tabs__nav-wrap::after {
  height: 1px;
  background-color: #efefef;
}
.examine >>> .el-tabs__item {
  padding: 0 30px;
}
.examine >>> .el-tabs__header {
  margin-bottom: 0;
}
.flight-itinerary >>> .el-tabs__header {
  margin-bottom: 0;
}
.flight-itinerary >>> .is-active {
  color: #337ab7;
}
.flight-itinerary >>> .el-tabs__active-bar {
  height: 2px;
  background-color: #337ab7;
}
.flight-itinerary >>> .el-tabs__nav-wrap::after {
  height: 1px;
  background-color: #efefef;
}
.flight-itinerary >>> .el-tabs__item {
  padding: 0 30px;
}
input::-webkit-input-placeholder {
  color: #dddddd;
}
/* Firefox */
input::-moz-placeholder {
  color: #dddddd;
  opacity: 1;
}
/* IE */
input:-ms-input-placeholder {
  color: #dddddd;
}
</style>